body {
  background: #000000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover {
  color: rgb(252, 215, 48);
}

.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #ffdf27;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

style attribute {
  background: rgb(51, 51, 51);
  color: rgb(0, 0, 0);
  align-items: center;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1.25rem;
  color: #212529;
  text-align: left;
  background-color: rgb(243, 243, 243);
  border: 1;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}

.bgjamkeys
{
  background-image: url("bgjamkeys.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 }

 .navbar-dark .navbar-nav .nav-link {
  color: #87fdbc;
}